<template>
  <div>
    <div class="contentsWidth mx-auto mt-4">

      <div class="row col-12">
        <p class="h5">申請管理 - 一覧</p>
      </div>

      <div>
        <b-row class="mt-2">
          <b-col sm="3" class="inputLabel">
            <label>年度</label>
          </b-col>
          <b-col sm="7">
            <b-form-select v-model="filter.year" :options="yearList">
            </b-form-select>
          </b-col>
        </b-row>
      </div>

      <div class="mt-4 float-right">
        <b-link to="/cms/contents/applications/create">新規登録</b-link>
      </div>

      <div id="tableWrap" class="mt-2">
        <b-pagination
          aria-controls="fieldTable"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        />

        <b-table striped hover
        id='fieldTable'
        :items="appTypeList"
        :fields="header"
        :filter="filter"
        :filter-function="filtering"
        show-empty
        :per-page="perPage"
        :current-page="currentPage"
        >
          <template v-slot:emptyfiltered="scope">
            <p class="h5">申請タイプがありません。</p>
          </template>
          <template v-slot:cell(btn)="row">
            <b-button size="sm" :to="'/cms/contents/applications/edit/'+row.item.id">
              編集
            </b-button>
            <b-button size="sm" class="ml-1">
              削除
            </b-button>
          </template>
        </b-table>
      </div>

    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
// import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'CmsContentsApplicationsList',
  data() {
    return {
      filter: {
        year: '',
      },
      yearList: [],
      appTypeList: [],
      header: [
        {
          key: 'name',
          label: '申請種別',
          sortable: true,
          thClass: 'text-center',
        },
        {
          key: 'application_announcement_datetime',
          label: '公示日時',
          sortable: true,
          thClass: 'text-center',
        },
        {
          key: 'open_datetime',
          label: '申請開始日時',
          sortable: true,
          thClass: 'text-center',
        },
        {
          key: 'status',
          label: '状態',
          sortable: true,
          thClass:
          'text-center',
        },
        {
          key: 'btn',
          label: '',
          sortable:
          false,
          thClass: 'text-center',
        },
      ],
      groupList: [],
      perPage: 5,
      currentPage: 1,
    };
  },
  methods: {
    // フィルター用 filter変更時に各行に対して走る
    filtering(lineData, filter) {
      const yearFilter = filter.year === '' || filter.year === lineData.year;
      return yearFilter;
    },
    initFetch() {
      const promiseFuncs = [api.send('/api/contents/applicationTypes/list')];
      api.all(promiseFuncs)
        .then((responses) => {
          this.appTypeList = responses[0].data.list;
          this.yearList = responses[0].data.yearList;
          this.filter.year = this.yearList[0] || '';
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch('page/offLoading');
        });
    },
  },
  computed: {
    rows() {
      return this.appTypeList.length;
    },
  },
  // ロード画面
  created() {
    this.$store.dispatch('page/onLoading');
    this.initFetch();
  },
};
</script>

<style scoped>

</style>
